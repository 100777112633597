import buffer from '@turf/buffer';
import intersects from '@turf/boolean-intersects';
import pointToLineDistance from '@turf/point-to-line-distance';
import __calculateHeading from '@turf/rhumb-bearing';
import { point } from '@turf/helpers';
import { featureReduce } from '@turf/meta';

const BUFFER_RADIUS = 0.1;
const units = 'kilometers';

export function getHeading(vessel, dockLines, originalHeading = 0) {
  const reducer = (featureList, dockLine) => {
    if (intersectsDock(dockLine, vessel)) {
      featureList.push(dockLine);
    }

    return featureList;
  };

  const dockLinesIntersecting = featureReduce(dockLines, reducer, []);

  if (!dockLinesIntersecting.length) {
    return originalHeading;
  }

  const {
    geometry: {
      coordinates: [start, end]
    }
  } = getClosestDock(dockLinesIntersecting, vessel);

  const dockHeading = calculateHeading(point(start), point(end));
  const complementaryHeading = calculateHeading(point(end), point(start));

  const headingDiff = Math.abs(dockHeading - originalHeading);
  const complementaryDiff = Math.abs(complementaryHeading - originalHeading);

  const resultHeading =
    headingDiff < complementaryDiff ? dockHeading : complementaryHeading;

  return resultHeading < 0 ? resultHeading + 360 : resultHeading;
}

function getClosestDock(dockLines, vessel) {
  const startValue = { dockLine: null, distance: Number.MAX_SAFE_INTEGER };

  const reducer = (closest, dockLine) => {
    const distance = pointToLineDistance(
      point(vessel.geometry.coordinates),
      dockLine
    );

    if (distance < closest.distance) {
      return { dockLine, distance };
    }

    return closest;
  };

  const { dockLine } = dockLines.reduce(reducer, startValue);

  return dockLine;
}

function intersectsDock(dockLine, vessel) {
  const bufferedDock = buffer(dockLine, BUFFER_RADIUS, { units });

  return intersects(bufferedDock, vessel);
}

function calculateHeading(a, b) {
  const heading = __calculateHeading(a, b);

  return heading < 0 ? heading + 360 : heading;
}
