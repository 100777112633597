import { Fill, Style, Stroke } from 'ol/style';

const polygonStyleFunction = function (feature) {
	const styleJson = JSON.parse(feature.getProperties().style);
	const fillColor = styleJson.f.c == '0' ? 'rgba(0, 0, 0, 0)' : styleJson.f.c;
	return new Style({
		stroke: new Stroke({
			color: styleJson.st.c,
			width: styleJson.st.w,
			lineDash: styleJson.st.ld
		}),
		fill: new Fill({
			color: fillColor
		})
	});
};
export default polygonStyleFunction;
