export default {
	computed: {
		cepOpts() {
			const opts = {
				model: '',
				page: 1,
				rows: null,
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [{ field: 'port_id', op: 'in', data: window.localStorage.getItem('workingPortsId') }]
				}
			};
			return opts;
		}
	},
	methods: {
		async getCepFeatures(bodyRequest, idLayer) {
			const response = await this.$puiRequests.postRequest('/puisearch', bodyRequest);
			const features = [];

			for (let i = 0, featuresLength = response.data.data.length; i < featuresLength; i++) {
				const feature = JSON.parse(JSON.stringify(response.data.data[i]));
				feature.idLayer = idLayer;
				feature.featureVisible = true;
				features.push(feature);
			}

			return features;
		}
	}
};
